import { Button, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonMailto from "../components/ButtonMailto";
import PlatformUrlUtils from "../utils/PlatformUrlUtils";

const Invite = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showSaved, setShowSaved] = React.useState(false);

  var timer: any = null;

  useEffect(() => {
    openFarewellNotes();
  }, []);

  function openFarewellNotes() {
    if (typeof window !== "undefined") {
      var now = new Date().getTime();
      const id = searchParams.get("id") ?? "";
      const platform = PlatformUrlUtils.getMobileOperatingSystem();
      if (platform === "iOS") redirectIOS(id);
      else redirectAndroid(id);
    }
  }

  function redirectAndroid(id: string) {
    setTimeout(function () {
      window.location.href = PlatformUrlUtils.getAndroidUrl();
    }, 25);
    window.location.href = `farewellnotes://invite?id=${id}`;
  }

  function setListener() {
    window.document.addEventListener("visibilitychange", function (e) {
      window.clearTimeout(timer);
      timer = null;
      const selfWindow = window.open("", "_self");
      if (selfWindow) {
        selfWindow.close();
      }
    });
  }

  function redirectIOS(id: string) {
    setListener();
    var beforeApp = new Date().valueOf();
    window.location.href = `farewellnotes://invite?id=${id}`;
    var afterApp = new Date().valueOf();
    if (afterApp - beforeApp < 100) {
      setTimeout(function () {
        window.location.href = PlatformUrlUtils.getIosUrl();
      }, 25);
    } else {
      if (timer === null) {
        timer = setTimeout(function () {
          window.location.href = PlatformUrlUtils.getIosUrl();
        }, 200);
      }
    }
  }

  return (
    <div className="container">
      <div>
        {isLoading ? (
          <CircularProgress className="loading" />
        ) : (
          <>
            {!showError ? (
              <div>
                <div className="content">
                  <div className="button-container">
                    {showSaved ? (
                      <p style={{ color: "green" }}>Thank you!</p>
                    ) : (
                      <Button
                        disabled={showSaved}
                        style={{
                          padding: "20px 40px",
                          width: "200px",
                          alignSelf: "center",
                        }}
                        onClick={async () => {
                          openFarewellNotes();
                        }}
                        variant="contained"
                      >
                        View Invite
                      </Button>
                    )}
                  </div>
                </div>
                <div style={{ margin: "10px", textAlign: "center" }}>
                  If you're unable to view the invitation in the Farewell Notes
                  app, please reach out to us via email:{" "}
                  <ButtonMailto
                    label="support@farewellnotes.com"
                    mailto="mailto:support@farewellnotes.com"
                  />
                </div>
              </div>
            ) : (
              <div>
                <p className="activity-error">
                  We encountered an error and couldn't open your invite. If this
                  issue persists, please reach out to us via email:{" "}
                  <ButtonMailto
                    label="support@farewellnotes.com"
                    mailto="mailto:support@farewellnotes.com"
                  />
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Invite;
