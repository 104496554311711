export default class PlatformUrlUtils {
  static getAndroidUrl(): string {
    return "https://play.google.com/store/apps/details?id=com.farewellnotes";
  }

  static getIosUrl(): string {
    return "https://apps.apple.com/us/app/farewell-notes/id6737527855";
  }

  static getMobileOperatingSystem() {
    var userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "iOS";
    }

    return "unknown";
  }
}
