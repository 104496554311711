import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ReactGA from "react-ga4";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import QuillLogo from "../../../../img/quill-logo.svg";

const pages = ["Home", "FAQ", "Privacy Policy", "Terms and Conditons"];

function NavBar() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  let showNavBar = !(
    ["/faq", "/terms", "/privacy"].includes(location.pathname) &&
    searchParams.get("hnf") === ""
  );

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    ReactGA.event({
      category: "Navigation",
      action: "Menu Click",
    });
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavigation = (page: any) => {
    switch (page) {
      case "Home":
        ReactGA.event({
          category: "Button",
          action: "Home",
        });
        navigate("/");
        break;
      case "FAQ":
        ReactGA.event({
          category: "Button",
          action: "FAQ",
        });
        navigate("/faq");
        break;
      case "Terms and Conditons":
        ReactGA.event({
          category: "Button",
          action: "Terms",
        });
        navigate("/terms");
        break;
      case "Privacy Policy":
        ReactGA.event({
          category: "Button",
          action: "Privacy",
        });
        navigate("/privacy");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {showNavBar && (
        <AppBar position="static" sx={{ backgroundColor: "white" }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <img height={30} src={QuillLogo} alt="Logo" />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href=""
                sx={{
                  color: "#4169E1",
                  fontFamily: "Itim",
                  fontSize: 25,
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  textDecoration: "none",
                }}
                onClick={() => {
                  handleNavigation("Home");
                }}
              >
                FAREWELL NOTES
              </Typography>
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontSize: 25,
                  textDecoration: "none",
                  color: "#4169E1",
                  fontFamily: "Itim",
                }}
                onClick={() => {
                  handleNavigation("Home");
                }}
              >
                FAREWELL NOTES
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  flexGrow: 0,
                  display: { xs: "none", md: "flex" },
                }}
              >
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={() => {
                      handleNavigation(page);
                    }}
                    sx={{ my: 2, color: "#344749", display: "block" }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon sx={{ color: "#344749", fontSize: 40 }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: "block", md: "none" } }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page}
                      onClick={() => {
                        handleNavigation(page);
                        handleCloseNavMenu();
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        {page}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  );
}
export default NavBar;
