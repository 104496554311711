import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PlatformUrlUtils from "../utils/PlatformUrlUtils";

const Download = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (typeof window !== "undefined") {
      var now = new Date().getTime();
      const os = searchParams.get("os");
      const platform = PlatformUrlUtils.getMobileOperatingSystem();
      if (os === "iOS") {
        if (platform === "Android")
          window.location.href = PlatformUrlUtils.getAndroidUrl();
        else window.location.href = PlatformUrlUtils.getIosUrl();
      } else {
        if (platform === "iOS")
          window.location.href = PlatformUrlUtils.getIosUrl();
        else window.location.href = PlatformUrlUtils.getAndroidUrl();
      }
    }
  }, []);

  return <div></div>;
};

export default Download;
